body,
html {
  height: 100%;
  margin: 0;
  /* background-color: #f6f3f0; */
}

.App {
  text-align: center;
}

#Header {
  height: 61px;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  /* border-bottom: 1px solid #e8e0d9; */
}

.header-logo-second {
  float: right;
  background: transparent url(components/Resources/Img/outgoing-call-2.svg) 0%
    0% no-repeat padding-box;
  width: 25px;
  height: 25px;
  margin-top: 12px;
  margin-right: 15px;
  padding-bottom: 5px;
}

#HeaderContainerPolestar {
  float: left;
  margin-left: 24px;
  margin-top: 24px;
}

.polestar-header-name-1 {
  font-family: "polestarunica";
  float: left;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 28px;
  letter-spacing: -1.2px;
  color: #000000;
}

.polestar-header-name-2 {
  font-family: "polestarunica";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 28px;
  letter-spacing: -1.2px;
  color: gray;
}

#HeaderContainerVolvia {
  float: left;
}

#info-container {
  left: 20px;
  padding-bottom: 20px;
  margin-top: 32px;

  margin-left: 20px;
  margin-right: 20px;
  padding-top: 20px;
}

#info-container-volvia {
  left: 20px;
  padding-bottom: 20px;
  padding-top: 5px;
}

#info-container-info {
  background: #faf9f7 0% 0% no-repeat padding-box;
  opacity: 1;
  padding-bottom: 20px;

  a:visited {
    color:#faf9f7;
  }
}

#info-container-info-volvia {
  background: transparent url(components/Resources/Img/background.png) 0% 0%
    no-repeat padding-box;
  opacity: 1;
  padding-bottom: 30px;
}

//clock icon 
.info-container-estimation {
  background: transparent url(components/Resources/Img/time-clock.svg) 0% 0%
    no-repeat padding-box;
  width: 28px;
  height: 33px;
  opacity: 1;
  background-size: contain;
  float: left;
  margin-right: 5px;
}

div[class^="info-container-product-text"] {
  position: absolute;
  visibility: hidden;
}

.info-container-contact-info {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 20px;
}

.info-container-info-block {
  padding-top: 10px;
  padding-bottom: 10px;
}

.info-container-contact-info-ETA {
  text-align: left;
  font: Bold 18px/22px If Sans;
  letter-spacing: 0px;
  color: #000000;
  margin-top: 3px;
  float: left;
}

.info-container-contact-info-ETA-volvia {
  text-align: center;
  letter-spacing: 0px;
  font-weight: bold;
  color: #000000;
  margin-top: 16px;
  font-family: "volvonovum";
}

.info-container-contact-info-ETA-bottom {
  height: 30px;
}

.FooterContainer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 20px;
}

#map-container {
  margin-top: 32px;
  margin-left: 20px;
  margin-right: 20px;

  height: 40vh;
}

#status-container {
  left: 20px;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  background: #faf9f7 0% 0% no-repeat padding-box;
  opacity: 1;
  padding-bottom: 50px;
  padding-right: 20px;
  padding-left: 20px;
}

.status-container-header {
  padding-top: 20px;
  width: 102px;
  height: 29px;
  text-align: left;
  letter-spacing: 0px;
  color: #332a26e6;
  opacity: 1;
  font-size: 1.4em;
  font-family: "ifregular";
}

.status-container-event {
  background: #faf9f7 0% 0% no-repeat padding-box;
  padding-bottom: 15px;
}

.status-container-event-text-block {
  margin-top: 41px;
}

.status-container-event-logo {
  width: 28px;
  height: 33px;
  opacity: 1;
  background-size: contain;
  padding-right: 20px;
  float: left;

  &.e-1 {
    background: transparent url(components/Resources/Img/events/data-download.svg)
    0% 0% no-repeat padding-box;
  }

  &.e-2 {
    background: transparent url(components/Resources/Img/events/recipe.svg) 0% 0%
    no-repeat padding-box;
  }

  &.e-3,
  &.e-6,
  &.e-8 {
    background: transparent url(components/Resources/Img/events/check.svg) 0% 0%
    no-repeat padding-box;
  }

  &.e-4,
  &.e-5,
  &.e-7 {
    background: transparent url(components/Resources/Img/events/assistance.svg) 0%
    0% no-repeat padding-box;
  }

  &.e-9 {
    background: transparent url(components/Resources/Img/events/wrench-tool.svg)
    0% 0% no-repeat padding-box;
  }

  &.e-10 {
    background: transparent url(components/Resources/Img/events/e-remove.svg) 0%
    0% no-repeat padding-box;
  }

  &.e-11 {
    background: transparent url(components/Resources/Img/events/c-warning.svg) 0%
    0% no-repeat padding-box;
  }
}

.status-container-event-name {
  float: left;
  text-align: left;
  font: Bold 18px/22px If Sans;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  width: 70%;
}

.status-container-event-time {
  text-align: right;
  font: normal normal normal 18px/22px "ifregular";
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.contact-page-close-button {
  float: right;
  background: transparent url(components/Resources/Img/close-cross.svg) 0% 0%
    no-repeat padding-box;
  width: 34px;
  height: 34px;
  margin-top: 20px;
}

.contact-body-block-text {
  text-align: center;
  font: normal normal normal 18px/22px If Sans;
  letter-spacing: 0px;
  color: #0054f0;
  opacity: 1;
  margin-bottom: 25px;
}

.contact-page-tel-1004, .contact-page-tel-1028{
  visibility: hidden !important;
  position: absolute;
}
.contact-page-tel2-1004, .contact-page-tel2-1028{
  visibility: visible !important;
  position: relative !important;
}

div[class^="contact-page-tel2"] {
  visibility: hidden;
  position: absolute;
}

.contact-body-block-header {
  text-align: center;
  font: normal normal bold 18px/22px If Sans;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-top: 30px;
  margin-bottom: 20px;
}

.contact-body-block {
  border-bottom: 1px solid #e8e0d9;
  padding-left: 30px;
  padding-right: 30px;
}

div[class^="contact-body-block-2"] {
  border-bottom: 1px solid #e8e0d9;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.contact-body-block-2-fi {
  visibility: hidden;
}

.contact-body-block-2 {
  border-bottom: 1px solid #e8e0d9;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.pin-texts {margin-top: 120px;}

@media (min-width: 500px) {.pin-texts { margin-top: 20px;}}

.pin-text-first {
  top: 267px;
  text-align: center;
  font: normal normal 300 38px/48px "ifregular";
  font-family: "ifregular";
  letter-spacing: 0px;
  color: #331e11e6;
  opacity: 1;
  margin-bottom: 24px;
}

.pin-text-first-polestar {
  font-family: "polestarunica";
  float: left;
  font-weight: 400;
  font-style: normal;
  font-size: 30px;
  line-height: 32px;
  letter-spacing: -1.2px;
  margin-left: 24px;
}

.pin-text-first-volvia {
  font-family: "volvonovum";
  float: left;
  font-weight: 400;
  font-style: normal;
  font-size: 30px;
  line-height: 32px;
  letter-spacing: -1.2px;
  margin-left: 24px;
}

.pin-text-second {
  text-align: center;
  font: normal normal normal 18px/22px "ifregular";
  font-family: "ifregular";
  letter-spacing: 0px;
  color: #331e11e6;
  opacity: 1;
  margin: auto;
  width: 80%;
}

.pin-text-second-polestar {
  font-family: "polestarunica";
  float: left;
  margin-left: 24px;
  text-align: left;
  margin-right: 24px;
  margin-bottom: 24px;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -1.2px;
  margin-top: 24px;
  width: calc(100% - 35px);
}

.pin-text-second-volvia {
  font-family: "volvonovumregular";
  float: left;
  margin-left: 24px;
  text-align: left;
  margin-right: 24px;
  margin-bottom: 24px;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -1.2px;
  margin-top: 24px;
  width: calc(100% - 35px);
}
*:focus {
  outline: none;
}

.pin-input-field {
  opacity: 1;
  width: 48px;
  height: 48px;
  text-align: center;
  margin-left: 20px;
  border: 1px solid #6e625e;
}

.pin-input-field-polestar {
  opacity: 1;
  width: 55px;
  height: 55px;
  text-align: center;
  margin-left: 12px;
  border: 0px solid #6e625e;
  background: linear-gradient(
      0deg,
      rgba(177, 179, 179, 0.15),
      rgba(177, 179, 179, 0.15)
    ),
    #ffffff;
}

input.pin-input-field-polestar:focus {
  border: 1px solid rgba(0, 0, 0, 0.15);
  opacity: 1;
}

input.pin-input-field:focus {
  border: 1px solid #0054f0 !important;
  opacity: 1;
}

.pin-input-fieldpin {
  opacity: 1;
  width: 48px;
  height: 48px;
  text-align: center;
  margin-left: 20px;
  border: 1px solid red;
}

input.pin-input-fieldpin:focus {
  border: 1px solid red !important;
  opacity: 1;
}

.pin-input-fieldserver {
  opacity: 1;
  width: 48px;
  height: 48px;
  text-align: center;
  margin-left: 20px;
  border: 1px solid red;
}

input.pin-input-fieldserver:focus {
  border: 1px solid red !important;
  opacity: 1;
}

.pin-inputs {
  padding-top: 10px;
  margin-right: 30px;
}

@media (min-width: 550px) {.pin-inputs { margin-right: 0;}}

.pin-inputs-polestar {
  padding-top: 30px;
  margin-right: 30px;
}

#error-warning {
  text-align: center;
  font: normal normal normal 18px/22px If Sans;
  letter-spacing: 0px;
  color: #b23e1d;
  opacity: 1;
  margin-top: 15px;
}

.loading-bar {
  width: 40px;
  height: 33px;
  background: transparent url(components/Resources/loaders/loader_blue.svg) 0%
    0% no-repeat padding-box;
  margin: auto;

  &.p-1003,
  &.p-1004,
  &.p-1005,
  &.p-1006,
  &.p-1008,
  &.p-1009,
  &.p-1010,
  &.p-1015,
  &.p-1030,
  &.p-1034,
  &.p-1036,
  &.p-1040,
  &.p-1042,
  &.p-1043,
  &.p-1044,
  &.p-1045 {
    background: transparent url(components/Resources/loaders/loader_black.svg) 0%
    0% no-repeat padding-box;
  }
}

.header-contact-entry {
  text-align: left;
  font: normal normal normal 13px/15px "ifregular";
  font-family: "ifregular";
  letter-spacing: 0px;
  color: #331e11;
  opacity: 1;
}

.header-phone-block {
  width: auto;
  float: right;
  margin-right: 3px;

  &.fi {
    display: none;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

* {
  -webkit-text-size-adjust: none !important;
  text-size-adjust: none !important;
}

@media screen and (max-width: 767px) {
  input,
  select,
  textarea {
    font-size: 16px;
  }
}

.overflow--auto {
  overflow: auto;
}

.overflow--scroll {
  overflow: scroll;
  height: 35rem;
}