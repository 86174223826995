/*IF 1001*/
.header-logo-first-1001,
.header-logo-first-2001 {
  background: transparent
  url(components/Resources/Img/If_Logotype_Blue_RGBTEST.png) 0% 0% no-repeat
  padding-box;
  float: left;
  left: 20px;
  width: 36px;
  height: 36px;
  margin-top: 12px;
  margin-left: 15px;
} 

/*Volvia 1003*/
.header-logo-first-1003, 
.header-logo-first-1008,
.header-logo-first-1009,
.header-logo-first-1010,

.header-logo-first-2003,
.header-logo-first-2008,
.header-logo-first-2009,
.header-logo-first-2010
 {
  float: left;
  margin-top: 23px;
  margin-left: 15px;

  svg {
    width: 125px;
    height: 36px;
  }
}

.header-logo-first-1042,
.header-logo-first-2042 {
  background: transparent url(components/Resources/Img/volvia.png) 0% 0%
    no-repeat padding-box;
  float: left;
  left: 20px;
  width: 250px;
  height: 36px;
  margin-top: 23px;
  margin-left: 15px;
}

/*Subaru 1005*/
.header-logo-first-1030,
.header-logo-first-2030,
.header-logo-first-1005,
.header-logo-first-2005
 {
  float: left;
  margin-top: 12px;
  margin-left: 15px;

  svg {
    width: 125px;
    height: 36px;
  }
}

.header-logo-first-1007,
.header-logo-first-2007 {
  float: left;
  margin-top: 12px;
  margin-left: 10px;

  svg {
    height: 40px;
  }
}

/*
BMW 1012
MG 1045
*/
.header-logo-first-1011,
.header-logo-first-1012,
.header-logo-first-2011,

.header-logo-first-2012 {
  float: left;
  margin-top: 5px;
  margin-left: 15px;

  svg {
    width: auto;
    height: 55px;
  }
}


.header-logo-first-1045,
.header-logo-first-2045 {
  float: left;
  margin-top: 5px;
  margin-left: 15px;

  svg {
    width: 170px;
    height: 55px;
  }
}


/*Ford 1013*/
.header-logo-first-1013 {
  float: left;
  margin-top: 0px;
  margin-left: 15px;

  svg {
    width: 90px;
    height: 55px;
  }
}

.header-logo-first-1006,
.header-logo-first-2006 {
  float: left;
  margin-top: 0px;
  margin-left: 15px;

  svg {
    width: 140px;
    height: 60px;
  }
}

/* MERCEDES 1015*/
.header-logo-first-1015,
.header-logo-first-2015 {
  background: transparent url(components/Resources/Img/mb_logo_svart_text.png) 0% 0% no-repeat
    padding-box;
  float: left;
  left: 20px;
  width: 250px;
  height: 55px;
  margin-top: 25px;
  margin-left: 10px;
  background-size: contain;
}

/* MINI 1016*/
.header-logo-first-1016,
.header-logo-first-2016 {
  background: transparent url(components/Resources/Img/mini.png) 0% 0% no-repeat
    padding-box;
  float: left;
  left: 20px;
  width: 250px;
  height: 55px;
  margin-top: 5px;
  margin-left: 15px;
}

/*Nissan 1017*/
.header-logo-first-1017,
.header-logo-first-2017 {
  float: left;
  /* left: 20px; */
  margin-top: 10px;
  margin-left: 15px;

  svg {
    width: auto;
    height: 40px;
  }
}

/*Land Rover 1036*/
.header-logo-first-1036,
.header-logo-first-2036  {
  background-image:  url(components/Resources/Img/LandRover.svg);
  width: 62px;
  height: 33px;
  background-size: contain;
  background-repeat: no-repeat;

  float: left;  
  margin-top: 10px;
  margin-left: 15px;
}

/*Jaguar 1037*/
.header-logo-first-1037,
.header-logo-first-2037 {
  float: left;
  margin-top: 15px;
  margin-left: 15px;

  svg {
    width: 100px;
    height: 55px;
  }
}

/*Mazda 1040*/
.header-logo-first-1040,
.header-logo-first-2040 {
  float: left;
  margin-top: 5px;
  margin-left: 10px;

  svg {
    width: 75px;
    height: 55px;
  }
}

/*Cupra*/
.header-logo-first-1043,
.header-logo-first-2043 {
  float: left;
  margin-top: 12px;
  margin-left: 15px;

  svg {
    width: 85px;
    height: 60px;
  }
}

/*SsangYong*/
.header-logo-first-1041,
.header-logo-first-2041 {
  float: left;
  margin-top: 20px;
  margin-left: 15px;

  svg {
    width: 155px;
    height: 22px;
  }
}

.info-container-product-text-fi {
  text-align: left;
  font: Bold 18px/22px If Sans;
  letter-spacing: 0px;
  color: #000000; 
}

div[class^="info-container-product-text"] {
  visibility: hidden;
  position: absolute;
}

.header-logo-first-1004,
.header-logo-first-2004 {
  opacity: 1;
  float: left;
  margin-top: 20px;
  margin-left: 15px;

  svg {
    width: 250px;
    height: 50px;
  }
}

.header-logo-first-1020,
.header-logo-first-1021,
.header-logo-first-1022,
.header-logo-first-1023,
.header-logo-first-1027,
.header-logo-first-2027,
.header-logo-first-1033 {
  background: transparent
    url(components/Resources/Img/If_Logotype_Blue_RGBTEST.png) 0% 0% no-repeat
    padding-box;
  float: left;
  left: 20px;
  width: 36px;
  height: 36px;
  margin-top: 12px;
  margin-left: 15px;
}

.header-logo-first-1028,
.header-logo-first-2028 {
  opacity: 1;
  float: left;
  margin-top: 20px;
  margin-left: 15px;

  svg {
    width: 250px;
    height: 50px;
  }
}

.header-logo-first-1034,
.header-logo-first-2034 {
  background: transparent url(components/Resources/Img/Polestar.png) 0% 0%
    no-repeat padding-box;
  float: left;
  left: 20px;
  width: 100px;
  height: 60px;
  margin-top: 0px;
  margin-left: 15px;
}

/*Hongqui 1044*/
.header-logo-first-1044,
.header-logo-first-2044 {
  background: transparent url(components/Resources/Img/hongqi-assistans.png) 0% 0%
  no-repeat padding-box; 
  float: left;
  background-size: contain;
  background-repeat: no-repeat;
  width: 250px;
  height: 55px;
  margin-top: 5px;
  margin-left: 15px;
}

/*Hedin 1046*/
.header-logo-first-1046,
.header-logo-first-2046 {
  background: transparent url(components/Resources/Img/hedin-auto.png) 0% 0%
  no-repeat padding-box; 
  float: left;
  background-size: contain;
  background-repeat: no-repeat;
  width: 250px;
  height: 55px;
  margin-top: 15px;
  margin-left: 20px;
}

#HeaderPolestar {
  width: 24px;
  height: 90px;
  left: 24px;
  top: 120px;
  font-size: 30px;
  line-height: 32px;
  letter-spacing: -1.2px;
  color: #101820;
  margin-left: 20px;
  margin-top: 20px;
  font-family: "polestarunica";
  font-weight: normal;
  font-style: normal;
}

.status-container-header-polestar {
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: -0.3px;
  font-size: 16px;
  line-height: 18px;
  font-family: "polestarunica";
  font-weight: normal;
  font-style: normal;
  text-align: left;
}

.status-container-event-polestar-header {
  font-size: 30px;
  line-height: 32px;
  letter-spacing: -1.2px;
  color: #101820;
  font-family: "polestarunica";
  font-weight: normal;
  font-style: normal;
  text-align: left;
  margin-top: 10px;
}

.status-container-event-volvia-header {
  font-size: 30px;
  line-height: 32px;
  letter-spacing: -1.2px;
  color: #101820;
  font-family: "volvonovum";
  font-weight: normal;
  font-style: normal;
  text-align: left;
  margin-top: 10px;
}

.status-container-event-polestar {
  text-align: left;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.3px;
  color: #101820;
  font-family: "polestarunica";
  font-weight: normal;
  font-style: normal;
  margin-top: 10px;
  z-index: 2;
  margin-bottom: 30px;
}

.status-container-event-volvia {
  text-align: left;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.3px;
  color: #101820;
  font-family: "polestarunica";
  font-weight: normal;
  font-style: normal;
  margin-top: 10px;
  z-index: 2;
  margin-bottom: 30px;
}

#status-container-polestar {
  margin-top: 32px;
  margin-left: 20px;
}

.status-container-event-volvia-time {
  text-align: right;
  float: right;
}

.status-container-event-polestar-1 { 
  display: inline-block !important;
  width: 300px;
  padding-left: 30px;
  transform: translate(0, -9%);
  padding-right: 10px;
  background-size: 100% 5px;
}

.status-container-event-volvia-1 {
  display: flex;
  justify-content: space-between;
  padding-left: 30px;
  transform: translate(0, -9%);
  padding-right: 10px;
  background-size: 100% 5px;
  font-family: "volvonovumregular";
}

.status-container-event-polestar-name2 {
  display: inline-block;
  width: 200px;
  transform: translate(0, -2%);
}

.status-container-event-polestar-name3 {
  transform: translate(0, -7%);
}

#info-container-info-polestar {
  /* Monochrome/Iron Grey */

  background: black;
  margin-left: 24px;
  margin-right: 20px;
  width: 203px;
  margin-bottom: 20px;

  height: 48px;
  line-height: 38px;
  text-align: center;
  bottom: 0;
}

#info-container-polestar {
  bottom: 0;
  position: fixed;
  width: 100%;
  z-index: 200;
  background: white;
}

.assistance-text-polestar {
  margin-left: 24px;
  font-family: "polestarunica";
  font-size: 16px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.6);
  text-align: initial;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

#info-polestar-logo {
  background: transparent
    url(components/Resources/Img/arrow_right_polestar_small.png) 0% 0% no-repeat
    padding-box;
  height: 24px;
  width: 24px;
  float: left;
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  margin-top: 20px;
}

// HEDIN
 #StatusContainerHedinMain {
  background-color: #0c0c0cfc;
   display: flex;
   align-items: center;
   justify-content: center;
   text-align: left;
   width: 100%;
   flex-direction: column;
   flex-wrap: nowrap;
 }

.container-hedin {
  padding: 15px;
  text-align: left;

  hr { border-width: thin;}
  .bold-text { font-weight: bold; }

  p {
    font-family: "hedin-regular";
    font-size: 16px;
    color: whitesmoke;
  }

  .container {
    min-width: 280px;
    width: 100%;
  }
  
  .bk-color-product {
  
    background: rgb(51 51 51 / 56%);
    opacity: 1;
    color: whitesmoke;
    font-size: 14px;
    padding-bottom: 10px;
  }
  

  .logo-viking-hedin {
    background: transparent url(components/Resources/Img/viking_logo.PNG) 0% 0% no-repeat padding-box;
    width: 105px;
    height: 25px;
    margin-right: 15px;
    padding-bottom: 5px;
    background-size: contain;
  }

  .time-container {
    margin-top: 15px;
    margin-bottom: 15px;

    div {
      font-weight: 600;
      font-size: 30px; 
    }
  }

}

.events-container-hedin {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  width: 100%;
  flex-direction: column;
  flex-wrap: nowrap;

  font-family: "hedin-regular";

  .grey-text { color: grey; }

  p {
    font-size: 16px;
    color: #0c0c0cfc;
  }

  .timeline-heding {
    text-align: left;
    position: relative;
    left: -95px;
    
    p {
      font-size: 1.4em;
      font-weight: 400;
    }
  }

  .events-handler {
    min-width: 280px;
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .status-text {
    margin-top: 4px;
    margin-left: 12px;
  }

  .line-container {
    margin-left: 10px;
  }
  
  .circle {
    margin-top: 5px;
    width: 20px;
    height: 20px;
    background-color: grey;
    border-radius: 50%;
  }
  
  .line {
    margin-top: 5px;
    width: 1px;
    height: 40px; 
    background-color: gray
  } 
  
  .active {
    .circle {
      background-color: rgba(245, 5, 55, 1);
    }
  
    .line {
      background-color: rgba(245, 5, 55, 1);
    }
  }  
}

div[class^="info-container-photo-container-1001"],
div[class^="info-container-photo-container-1004"],
div[class^="info-container-photo-container-1005"],
div[class^="info-container-photo-container-1006"],
div[class^="info-container-photo-container-1007"],
div[class^="info-container-photo-container-1008"],
div[class^="info-container-photo-container-1009"],
div[class^="info-container-photo-container-1010"],
div[class^="info-container-photo-container-1012"],
div[class^="info-container-photo-container-1013"],
div[class^="info-container-photo-container-1016"],
div[class^="info-container-photo-container-1017"],
div[class^="info-container-photo-container-1020"],
div[class^="info-container-photo-container-1021"],
div[class^="info-container-photo-container-1022"],
div[class^="info-container-photo-container-1023"],
div[class^="info-container-photo-container-1027"],
div[class^="info-container-photo-container-1028"],
div[class^="info-container-photo-container-1030"],
div[class^="info-container-photo-container-1033"],
div[class^="info-container-photo-container-1036"],
div[class^="info-container-photo-container-1037"],
div[class^="info-container-photo-container-1040"],
div[class^="info-container-photo-container-1041"],
div[class^="info-container-photo-container-1044"],
div[class^="info-container-photo-container-1045"] {
  text-align: left;
  float: left;
  margin-top: 4px;
  margin-right: 4px;
  width: 30%;
}

div[class^="info-container-photo-container-1001-fi"],
div[class^="info-container-photo-container-1012-fi"],
div[class^="info-container-photo-container-1013-fi"],
div[class^="info-container-photo-container-1016-fi"],
div[class^="info-container-photo-container-1017-fi"],
div[class^="info-container-photo-container-1036-fi"],
div[class^="info-container-photo-container-1037-fi"],
div[class^="info-container-photo-container-1040-fi"] {
  float: right;
}

div[class^="contact-page-button-1043"] {display: none;}
div[class^="contact-page-button-1046"] {display: none;}

.info-container-photo-container-1043-se{
  visibility: hidden;
  float: left;
  margin-top: 3px;
  margin-right: 2px;
}

div[class^="info-container-photo-container-1001"],
div[class^="info-container-photo-container-1004"],
div[class^="info-container-photo-container-1005"],
div[class^="info-container-photo-container-1006"],
div[class^="info-container-photo-container-1007"],
div[class^="info-container-photo-container-1008"],
div[class^="info-container-photo-container-1009"],
div[class^="info-container-photo-container-1010"],
div[class^="info-container-photo-container-1012"],
div[class^="info-container-photo-container-1013"],
div[class^="info-container-photo-container-1016"],
div[class^="info-container-photo-container-1017"],
div[class^="info-container-photo-container-1020"],
div[class^="info-container-photo-container-1021"],
div[class^="info-container-photo-container-1022"],
div[class^="info-container-photo-container-1023"],
div[class^="info-container-photo-container-1027"],
div[class^="info-container-photo-container-1028"],
div[class^="info-container-photo-container-1030"],
div[class^="info-container-photo-container-1033"],
div[class^="info-container-photo-container-1036"],
div[class^="info-container-photo-container-1037"],
div[class^="info-container-photo-container-1040"],
div[class^="info-container-photo-container-1041"],
div[class^="info-container-photo-container-1044"],
div[class^="info-container-photo-container-1045"] {
  text-align: left;
  float: left;
  margin-top: 4px;
  margin-right: 4px;
  width: 30%;
}

div[class^="info-container-photo-container-1001-fi"],
div[class^="info-container-photo-container-1012-fi"],
div[class^="info-container-photo-container-1013-fi"],
div[class^="info-container-photo-container-1016-fi"],
div[class^="info-container-photo-container-1017-fi"],
div[class^="info-container-photo-container-1036-fi"],
div[class^="info-container-photo-container-1037-fi"],
div[class^="info-container-photo-container-1040-fi"] {
  float: right;
}

.info-container-photo-container-1043-se{
  visibility: hidden;
  float: left;
  margin-top: 3px;
  margin-right: 2px;
}