.pr--1001 {
    font-family: "ifregular";
    background-color: #f6f3f0;
    text-align: left;

    h1 {color: #331E11;}
    a {font: normal normal normal 18px/40px "ifregular";}

    .language-bar {color: #331E11;}

    .container--base {
        background: #FAF9F7 0% 0% no-repeat padding-box;
        border-color: rgb(51 42 38 / 9%);
        color: #331E11;
    }

    .container--transparent {color: #6E625E;}

    .heading--bold {
        color: #331E11;
        font-family:  "ifbold";
    }

    .heading--informative { 
        color: #6E625E;
        font-family:  "ifregular";
    }

    .dropdown-language-bar {
        background-color: #FAF9F7;
        border-color: rgba(51, 42, 38, 0.09);
        color: #331E11;
    }    
}

.button--ifblue {
    background: #0054f0 0% 0% no-repeat padding-box;
    color: #FAF9F7;
    a {color: #FAF9F7;}
}

.button--ifgray {
    background: rgba(51, 30, 17, 0.05) 0% 0% no-repeat padding-box;
    color: #0054f0;
    a {color: #0054f0;}
}
.text--ifbrown {color: #331E11;}
.text--ifblue {color: #0054f0;}

.info-container-contact-info-call-button,
.contact-page-chatbot-button {
    &.p-1001,
    &.p-1012,
    &.p-1020,
    &.p-1021,
    &.p-1022,
    &.p-1023, 
    &.p-1027,
    &.p-1033  {
    background: #0054f0 0% 0% no-repeat padding-box;
    }

    &.p-1004, 
    &.p-1028 {
        background: black 0% 0% no-repeat padding-box;
    }

    &.p-1006 {
        font: normal normal normal 18px/40px "audiregular";
        background: black 0% 0% no-repeat padding-box;
    }

    &.p-1007,
    &.p-1011 {
        font: normal normal normal 18px/40px "volkswagen";
        background: #000b41 0% 0% no-repeat padding-box;
    }

    &.p-1008 {
        font: normal normal normal 18px/40px "skodaregular";
        background: #000b41 0% 0% no-repeat padding-box;
    }

    &.p-1009 {
        font: normal normal normal 18px/40px "seatregular";
        background: #000b41 0% 0% no-repeat padding-box;
    }

    &.p-1010 {
        font: normal normal normal 18px/40px "porcheregular";
        background: #000b41 0% 0% no-repeat padding-box;
    }

    &.p-1015 {
        font: normal normal normal 18px/40px "mercedes";
        background: #0078D6 0% 0% no-repeat padding-box;
    }

    &.p-1005,
    &.p-1016,
    &.p-1017,
    &.p-1030,
    &.p-1037,
    &.p-1040 
    {background: #000000 0% 0% no-repeat padding-box;}

    &.p-1013 {background: #033775 0% 0% no-repeat padding-box;}
    
    &.p-1036 {background: #004525 0% 0% no-repeat padding-box;}

    &.p-1041 {
        font: normal normal normal 18px/40px "ifregular";
        background: #000e3d 0% 0% no-repeat padding-box;
    }

    &.p-1003,
    &.p-1042 {
        font: normal normal normal 18px/40px "volvonovum";
        background: #547b83 0% 0% no-repeat padding-box;
    }

    &.p-1043 {
        padding-top: 6px;
        padding-bottom: 6px;
        font: normal normal normal 18px/40px "cupraregular";
        background: #000000 0% 0% no-repeat padding-box;
    }

    &.p-1044 {
        font: normal normal normal 18px/40px "hongqi";
        a {color: #000000;}
        background: #ffffff;
        padding: 10px 18px 10px 20px;
        border: solid #000000 1px;
    }

    &.p-1045 {
        font: normal normal normal 18px/40px "mg-regular";
        background: #000000 0% 0% no-repeat padding-box;
    }

    &.p-1046 {
        font: normal normal normal 18px/40px "hedin-regular";
        background: whitesmoke;
        text-align: center;
        cursor: pointer;
        height: 43px;
        width: 100%;
        margin-left: 0px;
      
        a {
          color: #0c0c0cfc;
        }
      }
}

.contact-page-chatbot-button {
    &.p-1004,
    &.p-1028,
    &.p-1044,
    &.p-1001-fi,
    &.p-1012-fi,
    &.p-1013-fi,
    &.p-1016-fi,
    &.p-1017-fi,
    &.p-1034-fi,
    &.p-1036-fi,
    &.p-1037-fi,
    &.p-1040-fi,
    &.p-1043
    {visibility: hidden;}
}

.product-font-1046 { font-family: "hedin-regular";}
.product-font-1045 { font-family: "mg-regular";}
.product-font-1044 { font-family: "hongqi";}
.product-font-1006 { font-family: "audiregular";}
.product-font-1008 { font-family: "skodaregular";}
.product-font-1009 { font-family: "seatregular";}
.product-font-1010 { font-family: "porcheregular";}
.product-font-1015 { font-family: "mercedes";}
.product-font-1007,
.product-font-1011
 { font-family: "volkswagen";}                                                   