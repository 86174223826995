/*Polestar Unica*/
@font-face {
    font-family: "polestarunica";
    src: url("../components/Resources/Fonts/PolestarFonts/PolestarUnica77TT-Regular.ttf");
  }
  
  /*Polestar Unica Bold*/
  @font-face {
    font-family: "polestarunicabold";
    src: url("../components/Resources/Fonts/PolestarFonts/PolestarUnica77-Bold.otf");
  }
  
  /*Volvo Novum-Medium*/
  @font-face {
    font-family: "volvonovum";
    src: url("../components/Resources/Fonts/VolviaFonts/Volvo-Novum-Medium.ttf");
  }
  
  @font-face {
    font-family: "volvonovumregular";
    src: url("../components/Resources/Fonts/VolviaFonts/Volvo-Novum-Regular.ttf");
  }
  
  @font-face {
    font-family: "hongqi";
    src: url("../components/Resources/Fonts/hongqi_regular.ttf");
  }
  
  @font-face {
    font-family: "skodaregular";
    src: url("../components/Resources/Fonts/Skoda/SKODANext-Regular.ttf")
  }
  
  @font-face {
    font-family: "audiregular";
    src: url("../components/Resources/Fonts/Audi/AudiTypeV03-Normal.otf");
  }
  
  @font-face {
    font-family: "cupraregular";
    src: url("../components/Resources/Fonts/Cupra/Cupra-Regular.otf");
  }

  @font-face {
    font-family: "mercedes";
    src: url("../components/Resources/Fonts/Mercedes/CorporateS-Regular.otf");
  }
  
  @font-face {
    font-family: "mg-regular";
    src: url("../components/Resources/Fonts/MG/FavoritStd-Regular.otf");
  }

  @font-face {
    font-family: "hedin-regular";
    src: url("../components/Resources/Fonts/Hedin/WorkSans-Regular.ttf");
  }
  
  @font-face {
    font-family: "porcheregular";
    src: url("../components/Resources/Fonts/Porche/PorscheNext-Regular.otf");
  }
  
  @font-face {
    font-family: "seatregular";
    src: url("../components/Resources/Fonts/Seat/SeatBcn-Regular.otf");
  }
  
  @font-face {
    font-family: "volkswagen";
    src: url("../components/Resources/Fonts/Volkswagen/VWHead-Regular.otf");
  }

  /*light Italic IF*/
@font-face {
    font-family: "lightitalicif";
    src: url("../components/Resources/Fonts/IfSans-Regular.otf");
  }
  
  /*bold IF*/
  @font-face {
    font-family: "ifbold";
    src: url("../components/Resources/Fonts/IfSans-Bold.otf");
  }
  
  /*IF regular*/
  @font-face {
    font-family: "ifregular";
    src: url("../components/Resources/Fonts/IfSans-Regular.otf");
  }

.padding--10 {padding: 10px;}
.margin--none {margin:0;}
.margin--left-6 {margin-left: 6px;}
.margin--left-10 {margin-left: 10px;}
.margin--right-10 {margin-right: 10px;}
.margin--left-20 {margin-left: 20px;}

.margin--top-6 {margin-top: 6px;}
.margin--top-10 {margin-top: 10px;}
.margin--top-20 {margin-top: 20px;}
.margin--top-30 {margin-top: 30px;}
.margin--top-40 {margin-top: 40px;}

.margin--bottom-20 {margin-bottom: 20px;}
.margin--bottom-30 {margin-bottom: 30px;}

.padding--none {padding: 0;}

.radius--6{border-radius: 6px;}

.row {
    display: flex;
    flex-direction: row;
}

.column {
    display: flex;
    flex-direction: column;
}

.aling--start {align-items: self-start;}
.no-display {display: none;}

.justify--space-between {justify-content: space-between;}
.justify--center {display: flex; justify-content: center;}
.justify--self-end {justify-self: end;}
.justify--self-start {justify-self: start;}

.font--size-22 {font-size: 22px;}

.text-left {text-align: left;}

#StatusContainerMain { margin-top: 104px;}
  