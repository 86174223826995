// NEW APP CSS
a {
    letter-spacing: 0px;
    text-decoration: none;

    &:visited {
        color: inherit;
    }
}

p {margin: 2px;}

h1 {
    opacity: 1;
    font-size: 1.4em;
    font-weight: 400;
}

//////////////////////////////////////
.button {
    opacity: 1;
    text-decoration: none;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    margin-top: 0;
    margin-bottom: 0;

    padding-top: 6px;
    padding-bottom: 6px;
}

.container--base {
    opacity: 1;
    border: 1px solid;

    margin-left: 20px;
    margin-right: 20px;

    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 20px;
}

.container--transparent {
    opacity: 1;
    background-color: transparent;
    border: none;

    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 20px;
}

.container--small {
    margin-left: 5px;
    margin-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 5px;
    padding-left: 5px;
}

.heading--bold {
    font-size: 18px;
    font-weight: 900;
}

.heading--informative {
    font-size: 16px;
    font-weight: 100;
}

.container-list {
    list-style: disc;
    padding: inherit;
    padding-left: 39px;
    line-height: 22px;

    li {
        margin: 0;

        a {font: normal normal normal 18px "ifregular";}

       &:last-child {
        a {text-decoration: underline;}
       }
    }
}

@mixin padding-12-margin-20 {
    padding-top: 12px;
    padding-bottom: 12px;
    margin-left: 20px;
    margin-right: 20px;
}
 
.info-container-contact-info-call-button {
    @include padding-12-margin-20;
    letter-spacing: 0px;
    opacity: 1;
    text-align: center;
    text-decoration: none;

    font: normal normal normal 18px/40px "ifregular";
    color: #faf9f7;
    a {color: #faf9f7;}
}

div[class^="info-container-contact-info-call-button-2"] {
    visibility: hidden;
    position: absolute;
  }

  #info-container-polestar-text {
    font-family: "polestarunica";
    font-size: 16px;
    
    letter-spacing: -0.3px;
    color: #ffffff;
    
    vertical-align: middle;
    line-height: normal;

    text-align: left;
    float: left;
    display: inline-block;

    margin-top: 16px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 24px;
  }

 .contact-page-chatbot-button {
    letter-spacing: 0px;
    font: normal normal normal 18px/40px "ifregular";
    color: #faf9f7;
    background: #0054f0 0% 0% no-repeat padding-box;
    opacity: 1;
    @include padding-12-margin-20;
    text-align: center;
    text-decoration: none;
    margin-top: 20px;
 } 
