
.polestar-events-list {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
  }

  .polestar-events-list:before {
    content: "";
    display: inline-block;
    width: 1px;
    background: black;
    position: absolute;
    left: 10px;
    top: 18px;
    height: calc(100% - 40px );
  }
  
  .polestar-events-list li {
    position: relative;
    margin-bottom: 15px;
  }
  
  .polestar-events-list li:before {
    z-index: 2;
    /* content: url(components/Resources/Img/dot.jpg); */
    content: url(Resources/dot.jpg);

    width:21px;
    height: 21px;
    position: absolute;
    left: 0;
    display: inline-block; 
    left: 0 !important; 
    top:-3px;
  }
  
  .polestar-events-list li:first-child:before {
    content: url(Resources/dotorange.jpg);
    width:21px;
    height: 21px;
    position: absolute;
    left: 0;
    display: inline-block; 
    /* left: -3px !important; */
    top:-3px;
  }

  .polestar-events-dot {
    z-index: 2;
    /* content: url(components/Resources/Img/dot.jpg); */
    content: url(Resources/dot.jpg);

    width:21px;
    height: 21px;
    position: absolute;
    left: 0;
    display: inline-block; 
    left: 0 !important; 
    top:-3px;
  }