
ul {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
  }

  #status-container-main-volvia ul:before {
    content: "";
    display: inline-block;
    width: 1px;
    background: black;
    position: absolute;
    left: 7px;
    top: 18px;
    height: calc(100% - 40px );
  }

  ul li {
    position: relative;
    margin-bottom: 15px;
  }
  
  .volvia-events-list li:before {
    z-index: 2;
    content: url(Resources/dotV.jpg);
    width:21px;
    height: 21px;
    position: absolute;
    left: 0;
    display: inline-block; 
    left: -3px !important;
    top:-3px;
  }
  
  .volvia-events-list li:first-child:before {
    content: url(Resources/dotvolvia.jpg);
    width:21px;
    height: 21px;
    position: absolute;
    left: 0;
    display: inline-block; 
    left: -3px !important;
    top:-3px;
  }